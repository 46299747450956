import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { type FC, useState } from "react";
import { AssignmentModal } from "./Modals/AssignmentModal";
import { Button } from "@metronome/components/ui/button";
import { FormattedMessage } from "react-intl";

type CreateAssignmentProps = {
	contextId: string;
	roleId: string;
	context: "step-instances" | "process-instances";
};

export const CreateAssignment: FC<CreateAssignmentProps> = ({
	context,
	contextId,
	roleId,
}) => {
	const [open, setOpen] = useState(false);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline">
					<FormattedMessage id="ADD_ASSIGNEE" />
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						<FormattedMessage id={"ADD_ASSIGNEE"} />
					</DialogTitle>
				</DialogHeader>
				<AssignmentModal
					contextId={contextId}
					roleId={roleId}
					context={context}
					onSuccess={() => setOpen(false)}
				/>
			</DialogContent>
		</Dialog>
	);
};
