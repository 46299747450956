import {
	type AuthenticationResult,
	type EventMessage,
	EventType,
	InteractionRequiredAuthError,
	type RedirectRequest,
} from "@azure/msal-browser";
import * as Sentry from "@sentry/react";
import axios, { type AxiosResponse } from "axios";
import QueryString from "qs";
import { createIntl, createIntlCache } from "react-intl";
import { toast } from "sonner";
import { z } from "zod";
import { loginRequest, msalInstance } from "../authConfig";
import { allMessages } from "../i18n/i18nProvider";

const accessTokenRequest: RedirectRequest = {
	scopes: loginRequest.scopes,
};
// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();
const i18nConfig = localStorage.getItem("i18nConfig");
let locale = "en";
if (i18nConfig === "fr") {
	locale = i18nConfig;
}

const intl = createIntl(
	{
		locale,
		messages: allMessages[locale as keyof typeof allMessages],
	},
	cache,
);

export type ExpectedErrorResponseType = {
	message: string;
};

const transactionId = Math.random().toString(36).substring(2, 9);
Sentry.setTag("transaction_id", transactionId);

type ApiCall = <T>(
	url: string,
	params: Record<string, unknown>,
) => Promise<AxiosResponse<T> | { data: undefined }>;

const accounts = msalInstance.getAllAccounts();

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

if (accounts.length === 1) {
	msalInstance.setActiveAccount(accounts[0]);
} else {
	accessTokenRequest.prompt = "select_account";
}

msalInstance.addEventCallback((event: EventMessage) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
		const payload = event.payload as AuthenticationResult;
		const { account } = payload;
		msalInstance.setActiveAccount(account);
	}
});

const defaultConfig: {
	baseURL: string;
	headers?: Record<string, string>;
} = {
	baseURL: window.VITE_API_URL || import.meta.env.VITE_API_URL,
	headers: {
		"Content-Type": "application/json",
		"sentry-trace": transactionId,
	},
};

/**
 * the axios instance is sitting here at the moment
 * but it could be instanciated and shared via context
 */

const axiosInstance = axios.create(defaultConfig);

async function issueToken(): Promise<AuthenticationResult | undefined> {
	try {
		await msalInstance.handleRedirectPromise();
		const response = await msalInstance.acquireTokenSilent({
			...loginRequest,
			redirectUri: `${window.location.origin}/blank.html`,
			account: msalInstance.getActiveAccount() ?? undefined,
		});
		msalInstance.setActiveAccount(response.account);
		return response;
	} catch (error) {
		// Acquire token silent failure, and send an interactive request
		if (error instanceof InteractionRequiredAuthError) {
			msalInstance.handleRedirectPromise().then(() => {
				msalInstance
					.acquireTokenRedirect({
						...accessTokenRequest,
						account: msalInstance.getActiveAccount() ?? undefined,
						redirectUri: window.location.origin,
					})
					.catch((e) => {
						Sentry.captureException(e);
					});
			});
		}
		return undefined;
	}
}

const errorHandler = (error: {
	response: { status: number; statusText: string; config: { url: string } };
}): Promise<never> => {
	const { response } = error;
	if (response?.status === 500) {
		toast.error(
			`There was an error with the api request /${response.config.url}: ${response.status} ${response.statusText}`,
		);
	}
	if (response?.status === 404) {
		toast.error(
			`The resource has not been found /${response.config.url}: ${response.status} ${response.statusText}`,
		);
	}
	if (response && response.status === 401) {
		toast.warning("Authorization is invalid. Please try again");
	}
	return Promise.reject(error);
};

axiosInstance.interceptors.request.use(async (config) => {
	const controller = new AbortController();

	const response = await issueToken();
	if (!response.accessToken) {
		controller.abort();
	}

	if (config.headers) {
		const i18n = window.localStorage.getItem("i18nConfig");
		config.headers["Content-Type"] = "application/json";
		config.headers["Accept-Language"] = i18n ?? navigator.language;
		config.headers.Authorization = `Bearer ${response?.accessToken}`;
	}

	return config;
}, errorHandler);

const X_BUILD_VERSION = "x-build-version";
axiosInstance.interceptors.response.use((response) => {
	const apiBuildVersion = response.headers[X_BUILD_VERSION];
	if (!apiBuildVersion) return response;
	const latestKnownBuildVersion = localStorage.getItem(X_BUILD_VERSION);
	if (latestKnownBuildVersion && apiBuildVersion !== latestKnownBuildVersion) {
		toast.dismiss();
		toast.info(intl.formatMessage({ id: "PLEASE_RELOAD_APPLICATION" }));
	}
	localStorage.setItem(X_BUILD_VERSION, apiBuildVersion);
	return response;
});

export const apiGet = async <T>(
	url: string,
	params: Record<string, unknown> = {},
): Promise<AxiosResponse<T>["data"]> => {
	const response = await axiosInstance.get<T>(url, {
		...params,
		paramsSerializer: (p: unknown) =>
			QueryString.stringify(p, {
				arrayFormat: "repeat",
				encode: true,
			}),
	});
	return response.data;
};

export const apiPost = async <T>(
	url: string,
	params: Record<string, unknown> = {},
	config: Record<string, unknown> = {},
): Promise<AxiosResponse<T>> => {
	const response = await axiosInstance.post<T>(url, params, config);
	return response;
};

const recordToFormData = (
	params: Record<string, string | Blob | undefined> = {},
): FormData => {
	const formData = new FormData();
	for (const [key, value] of Object.entries(params)) {
		if (value) {
			formData.append(key, value);
		}
	}
	return formData;
};

export const apiForm = async <T>(
	url: string,
	method: "post" | "put",
	params: Record<string, string | Blob | undefined> = {},
): Promise<T> => {
	const token = await issueToken();

	const response = await fetch(
		`${window.VITE_API_URL || import.meta.env.VITE_API_URL}/${url}`,
		{
			method,
			body: recordToFormData(params),
			credentials: "include",
			headers: {
				Authorization: `Bearer ${token?.accessToken}`,
			},
		},
	);

	if (!response.ok) {
		const message = `An error has occured: ${response.status}`;
		throw new Error(message);
	}

	const data = await response.json();
	return data;
};

export const apiPut: ApiCall = async <T>(
	url: string,
	params: Record<string, unknown> = {},
): Promise<AxiosResponse<T> | { data: undefined }> => {
	const response = await axiosInstance.put<T>(url, params);
	return response;
};

export const apiDelete = async (
	url: string,
	params: Record<string, unknown> = {},
): Promise<AxiosResponse | { data: undefined }> => {
	const response = await axiosInstance.delete(url, { data: params });
	return response as AxiosResponse<unknown>;
};

export const HTTPMethod = z.enum(["GET", "POST", "PUT"]);
export type HTTPMethod = z.infer<typeof HTTPMethod>;

export default function typedApi<Request, Response>({
	method,
	requestSchema,
	responseSchema,
}: {
	method: HTTPMethod;
	requestSchema?: z.ZodType<Request>;
	responseSchema: z.ZodType<Response>;
}): (path: string, requestData: Request) => Promise<Response> {
	return function typedApiWithParams(path: string, requestData: Request) {
		if (requestSchema) requestSchema.parse(requestData);
		async function apiCall(): Promise<Response> {
			const response = await axiosInstance({
				baseURL: window.VITE_API_URL || import.meta.env.VITE_API_URL,
				method,
				url: path,
				[method === HTTPMethod.enum.GET ? "params" : "data"]: requestData,
				paramsSerializer: (p) =>
					QueryString.stringify(p, {
						arrayFormat: "repeat",
						encode: true,
					}),
			});

			if (import.meta.env.PROD) {
				responseSchema.safeParseAsync(response.data).then((result) => {
					if (!result.success) {
						const formatted = result.error.format();
						Sentry.captureException(new Error(formatted._errors.join()), {
							extra: { path, method, error: result.error },
						});
					}
				});

				return response.data as Response;
			}

			responseSchema.safeParseAsync(response.data).then((result) => {
				if (!result.success) {
					toast.error(
						`Zod safeParseAsync failed on response from ${path} with method ${method}`,
					);
					console.error(result.error.issues);
				}
			});

			return response.data as Response;
		}

		return apiCall();
	};
}
