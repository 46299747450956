import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type React from "react";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import useAttachments from "@metronome/api/useAttachments";
import {
	useStepInstanceRequirements,
	useStepInstanceViews,
} from "@metronome/api/useStepInstance";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";

import { useNodeReferenceSpecs } from "@metronome/api/useMetadataDefinitions";
import { AvatarsList } from "@metronome/components/AvatarsList";
import { CopyLinkButton } from "@metronome/components/CopyLinkButton";
import { Dialog, DialogContent } from "@metronome/components/Dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@metronome/components/Dropdown";
import Flag from "@metronome/components/Flag";
import SectionMessage from "@metronome/components/SectionMessage";
import { TimelinessLozenge } from "@metronome/components/TimelinessTag";
import { APPEARANCES } from "@metronome/constants/theme";
import { CreateAssignment } from "@metronome/features/CreateAssignment";
import { FeaturedMetadata } from "@metronome/features/FeaturedMetadata";
import { NodeReference } from "@metronome/features/Modals/ReferenceModal";
import { PartyViews } from "@metronome/features/PartyViews";
import { RequiredAttachmentsCore } from "@metronome/features/RequiredAttachments";
import { SendFileAttachment } from "@metronome/features/SendFileAttachment";
import { SendLinkAttachment } from "@metronome/features/SendLinkAttachment";
import { StepSummary } from "@metronome/features/Steps/Summary";
import { UpdateAssignments } from "@metronome/features/UpdateAssignments";
import { UpdateInstanceResolution } from "@metronome/features/UpdateInstanceResolution";
import { useIsMobile } from "@metronome/hooks/useResponsive";
import type { StepInstanceAttachments } from "@metronome/types/Attachment";
import type { INodeReferenceSpec } from "@metronome/types/BusinessDimension";
import type {
	IStepInstance,
	IStepInstanceView,
	IStepRequirements,
} from "@metronome/types/StepInstance";
import { isProcessActive } from "@metronome/utils/isProcessActive";
import { getTimelinessFromStepInstance } from "@metronome/utils/timeliness";
import { EnterIcon, LockClosedIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { Link } from "@tanstack/react-router";
import styles from "./stepInstanceTemplate.module.scss";
import { useStepInstanceAssignments } from "@metronome/api/useAssignments";

import { Badge } from "@metronome/components/ui/badge";

type ActionButtonsProps = {
	stepInstance: IStepInstance;
	requirements?: IStepRequirements[];
	attachments?: StepInstanceAttachments;
	referenceSpecs?: INodeReferenceSpec[];
	stepInstanceViews?: IStepInstanceView[];
};

type SelectedActions =
	| "addRequiredAttachments"
	| "addLinkAttachments"
	| "addFileAttachments"
	| "addReference"
	| "viewBy";

const ActionButtonsDropdown = ({
	attachments,
	stepInstance,
	referenceSpecs,
	stepInstanceViews,
	requirements,
}: ActionButtonsProps): JSX.Element => {
	const [selectedAction, setSelectedAction] = useState<
		SelectedActions | undefined
	>(undefined);

	const onOpenChange = useCallback((open: boolean) => {
		if (!open) setSelectedAction(undefined);
	}, []);

	const hasMissingAttachmentsRequired =
		requirements?.find((requirement) => requirement.name === "attachments")
			?.fulfilled === false;

	return (
		<>
			<DropdownMenu modal={false}>
				<DropdownMenuTrigger asChild>
					<button type="button" className={styles.actionTrigger}>
						<FontAwesomeIcon icon={["fas", "ellipsis-vertical"]} />
						{!!hasMissingAttachmentsRequired && (
							<div className={styles.dotButton} />
						)}
					</button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className={styles.actionContent} align="end">
					{!!hasMissingAttachmentsRequired && (
						<DropdownMenuItem
							className={clsx({
								[styles.actionItem]: true,
								[styles.required]: !!hasMissingAttachmentsRequired,
							})}
							onSelect={() => setSelectedAction("addRequiredAttachments")}
						>
							<FontAwesomeIcon
								className="color-link"
								fixedWidth
								icon={["fas", "file"]}
							/>
							<FormattedMessage id="SEND_REQUIRED_ATTACHMENTS" />
						</DropdownMenuItem>
					)}
					<DropdownMenuItem
						className={styles.actionItem}
						onSelect={() => setSelectedAction("addLinkAttachments")}
					>
						<FontAwesomeIcon
							className="color-link"
							fixedWidth
							icon={["fas", "link"]}
						/>
						<FormattedMessage id="SEND_ATTACHMENT_LINK" />
					</DropdownMenuItem>
					<DropdownMenuItem
						className={styles.actionItem}
						onSelect={() => setSelectedAction("addFileAttachments")}
					>
						<FontAwesomeIcon
							className="color-link"
							fixedWidth
							icon={["fas", "upload"]}
						/>
						<FormattedMessage id="SEND_ATTACHMENT_FILE" />
					</DropdownMenuItem>
					{!!referenceSpecs?.length && (
						<DropdownMenuItem
							className={styles.actionItem}
							onSelect={() => setSelectedAction("addReference")}
						>
							<FontAwesomeIcon
								className="color-link"
								fixedWidth
								icon={["fas", "circle-nodes"]}
							/>
							<FormattedMessage id="ADD_NODE_REFERENCE" />
						</DropdownMenuItem>
					)}
					<DropdownMenuItem
						className={styles.actionItem}
						onSelect={() => setSelectedAction("viewBy")}
					>
						<FontAwesomeIcon
							className="color-link"
							fixedWidth
							icon={["fas", "eye"]}
						/>
						<FormattedMessage id="VIEW_BY" />
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
			<Dialog modal={false} open={!!selectedAction} onOpenChange={onOpenChange}>
				<DialogContent size="small">
					<>
						{selectedAction === "addRequiredAttachments" && (
							<RequiredAttachmentsCore
								attachments={attachments}
								context="step-instances"
								contextId={stepInstance.id}
								onSuccess={() => onOpenChange(false)}
							/>
						)}
						{selectedAction === "addLinkAttachments" && (
							<SendLinkAttachment
								context="step-instances"
								contextId={stepInstance.id}
								onSuccess={() => onOpenChange(false)}
							/>
						)}
						{selectedAction === "addFileAttachments" && (
							<SendFileAttachment
								context="step-instances"
								contextId={stepInstance.id}
								onSuccess={() => onOpenChange(false)}
							/>
						)}
						{!!referenceSpecs?.length && selectedAction === "addReference" && (
							<NodeReference
								stepInstanceId={stepInstance.id}
								node={stepInstance.businessDimension}
								nodeReferences={stepInstance.nodeReferences}
								referenceSpecs={referenceSpecs}
								onSuccess={() => onOpenChange(false)}
							/>
						)}
						{selectedAction === "viewBy" && (
							<PartyViews views={stepInstanceViews} />
						)}
					</>
				</DialogContent>
			</Dialog>
		</>
	);
};

type RequirementsListProps = {
	requirements: IStepRequirements[];
	processInstanceId: string;
};
const RequirementsList: React.FC<RequirementsListProps> = ({
	requirements,
	processInstanceId,
}) => {
	const intl = useIntl();
	const workspaceId = useWorkspaceId();

	return (
		<ul className="mb-0">
			{requirements?.map((requirement) => (
				<li className="pb-2" key={requirement.name}>
					<span className="font-bold">
						{intl.formatMessage({
							id: `REQUIRED_${requirement.name?.toUpperCase()}`,
						})}
					</span>
					<ul key="requirementsLinks" className="ms-8 list-disc">
						{requirement.name === "previousStepInstances" &&
							requirement.ref?.map((ref) => (
								<li key={ref.name}>
									<Link
										className="truncate hover:underline"
										to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
										params={{
											workspaceId,
											processId: processInstanceId,
											stepId: ref.id,
										}}
									>
										{ref.name}
									</Link>
								</li>
							))}
						{requirement.name === "attachments" &&
							requirement.ref?.map((ref) => <li key={ref.id}>{ref.name}</li>)}
						{requirement.name === "forms" &&
							requirement.ref?.map((ref) => <li key={ref.id}>{ref.name}</li>)}
					</ul>
				</li>
			))}
		</ul>
	);
};

type StepHeaderProps = {
	stepInstance: IStepInstance;
	requirements?: IStepRequirements[];
	isFromAnActiveProcess: boolean;
	showSidePanel: boolean;
	setShowSidePanel: (showSidePanel: boolean) => void;
};

const StepHeader: React.FC<StepHeaderProps> = ({
	stepInstance,
	requirements,
	isFromAnActiveProcess,
	showSidePanel,
	setShowSidePanel,
}) => {
	const workspaceId = useWorkspaceId();

	const { data: attachments } = useAttachments<Context>(
		"step-instances",
		stepInstance.id,
	);

	const timeliness = getTimelinessFromStepInstance(
		stepInstance.schedule,
		stepInstance.completion,
		stepInstance.type,
	);

	const nodeNames = stepInstance.businessDimension.name;

	const { data: referenceSpecs } = useNodeReferenceSpecs(
		stepInstance.businessDimension.id,
	);

	const { data: stepInstanceViews } = useStepInstanceViews(stepInstance.id);

	return (
		<div
			className={`flex flex-row gap-2 justify-between p-4 ${styles.stickyTitle}`}
		>
			<div className="flex flex-row flex-wrap items-center gap-2 w-full">
				<div className="flex group">
					<Link
						to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
						params={{
							workspaceId,
							processId: stepInstance.processInstance.id,
							stepId: stepInstance.id,
						}}
					>
						<h1
							className={`text-lg font-bold mb-0 ${
								isFromAnActiveProcess ? "text-primary" : "text-slate-600"
							} `}
						>
							{stepInstance.step.name}{" "}
						</h1>
					</Link>
					<CopyLinkButton
						className="group-hover:opacity-100 group-hover:translate-x-0"
						link={`${window.location.origin}/${workspaceId}/process/${stepInstance.processInstance.id}/step/${stepInstance.id}`}
					/>
				</div>
				<Badge variant="outline">{nodeNames}</Badge>
				{!!timeliness && timeliness !== "onTrack" ? (
					<TimelinessLozenge timeliness={timeliness} />
				) : null}
			</div>

			<div className="flex flex-row items-center gap-2">
				<Flag
					isFlagged={stepInstance.isFlagged}
					context="step-instances"
					id={stepInstance.id}
				/>
				<ActionButtonsDropdown
					stepInstance={stepInstance}
					referenceSpecs={referenceSpecs}
					stepInstanceViews={stepInstanceViews}
					attachments={attachments}
					requirements={requirements}
				/>
				<button
					type="button"
					onClick={() => setShowSidePanel(!showSidePanel)}
					className={clsx({
						[styles.actionTriggerSidePanel]: true,
						[styles.actionTriggerActive]: showSidePanel,
					})}
				>
					<EnterIcon className="inline" />
				</button>
			</div>
		</div>
	);
};

type Context = "step-instances";

export const StepInstanceTemplate: React.FC<{
	stepInstance: IStepInstance;
	isSideOpen?: boolean;
	children: React.ReactNode;
}> = ({ stepInstance, children }) => {
	const isMobile = useIsMobile();
	const [showSidePanel, setShowSidePanel] = useState(!isMobile);

	const isFromAnActiveProcess = isProcessActive(
		stepInstance.processInstance.state,
	);

	// only enabled if resolution is not done or cancel
	const { data } = useStepInstanceRequirements(
		stepInstance.id,
		stepInstance?.resolution,
	);

	// TODO : Use the Assignment Wrapper that contains the parties + assignment metadata (origin, role name, etc..)
	const { data: assignmentsMap } = useStepInstanceAssignments(stepInstance.id);

	const requirements = data?.filter((requirement) => !requirement.fulfilled);

	return (
		<div
			className={`max-w-full flex flex-row flex-wrap md:flex-nowrap ${styles.heightFillAvailable}`}
		>
			<div className="flex flex-col grow gap-2 bg-grey relative overflow-auto">
				<div className={`${styles.heightOverflow}`}>
					<StepHeader
						stepInstance={stepInstance}
						requirements={requirements}
						isFromAnActiveProcess={isFromAnActiveProcess}
						showSidePanel={showSidePanel}
						setShowSidePanel={setShowSidePanel}
					/>
					<div className="flex flex-col text-break gap-4 ps-4 pe-4 pt-4 pb-6">
						{!!requirements?.length && (
							<div className="mb-2 mt-2">
								<SectionMessage appearance={APPEARANCES.warning}>
									<RequirementsList
										requirements={requirements}
										processInstanceId={stepInstance.processInstance.id}
									/>
								</SectionMessage>
							</div>
						)}

						<div className="flex flex-row gap-8">
							<div>
								{!!assignmentsMap && (
									<div className="flex flex-row gap-4 flex-wrap">
										{assignmentsMap.map((assignmentsGroup) => {
											return (
												<div
													key={assignmentsGroup.role.id}
													className="flex flex-col"
												>
													<div className="uppercase text-xs font-semibold pb-2">
														{assignmentsGroup.role.name}
													</div>
													{assignmentsGroup.assignments.length > 0 ? (
														<AvatarsList
															numberToDisplay={
																assignmentsGroup.assignments.length
															}
															users={assignmentsGroup.assignments.map((a) => ({
																...a.party,
																origin: a.origin,
																status: a.status,
																roleName: assignmentsGroup.role.name,
															}))}
															size={36}
														>
															<UpdateAssignments
																context="step-instances"
																contextId={stepInstance.id}
																assignmentsGroup={assignmentsGroup}
															/>
														</AvatarsList>
													) : (
														<div>
															<CreateAssignment
																context="step-instances"
																contextId={stepInstance.id}
																roleId={assignmentsGroup.role.id}
															/>
														</div>
													)}
												</div>
											);
										})}
									</div>
								)}
							</div>
						</div>

						<FeaturedMetadata
							contextId={stepInstance.id}
							context="step-instances"
							businessDimension={stepInstance.businessDimension}
						/>
						{children}
					</div>
					<div className={styles.bottomSticky}>
						{!isFromAnActiveProcess && (
							<>
								<LockClosedIcon />
								<FormattedMessage id="WARNING_PROCESS_CLOSED" />
							</>
						)}
						<UpdateInstanceResolution stepInstance={stepInstance} />
					</div>
				</div>
			</div>
			<aside
				className={clsx({
					[styles.sidePanel]: true,
					[styles.heightOverflow]: true,
					[styles.sidePanelVisible]: showSidePanel,
				})}
			>
				<StepSummary
					showSidePanel={showSidePanel}
					setShowSidePanel={setShowSidePanel}
					stepInstance={stepInstance}
					isSideOpen={false}
					businessDimension={stepInstance.businessDimension}
				/>
			</aside>
		</div>
	);
};
