import {
	singleProcessStreamsOptions,
	useProcessStreamResourceAllocationDefinitions,
} from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { PageTitle } from "@metronome/context/PageData";
import { ProcessStreamTemplate } from "@metronome/features/ProcessStreamTemplate";
import { FormattedMessage, useIntl } from "react-intl";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import { ProcessTimelineLoader } from "./-timeline";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { UserProcessConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import type { ProcessContext } from "@metronome/types/ProcessInstance";
import useUserPreferences from "@metronome/api/useUserPreference";
import { useState } from "react";
import * as v from "valibot";
import { SelectResource } from "@metronome/features/SelectResource";
import { useStepInstancesFromPorfolio } from "@metronome/api/useStepInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { FilterDateRange } from "@metronome/components/FilterDateRange";
import { atEndOfDay, atMidnight } from "@metronome/utils/dateHelpers";
import { addDays } from "date-fns";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Button } from "@metronome/components/ui/button";
import { StepInstanceFromCluster } from "./-stepsTimelineGrouped";

type StepInstancesByRessourceProps = {
	streamId: string;
	from: string;
	to: string;
	stepTemplateId: string;
	nodeReferenceId: string;
};
const StepInstancesByRessource: React.FC<StepInstancesByRessourceProps> = ({
	streamId,
	from,
	to,
	stepTemplateId,
	nodeReferenceId,
}) => {
	const { data, isLoading } = useStepInstancesFromPorfolio({
		streamId,
		from: atMidnight(from)?.toJSON(),
		to: atEndOfDay(to)?.toJSON(),
		stepTemplateId,
		nodeReferenceId,
	});
	if (isLoading) return <LoadingMetronome />;
	if (data) return <StepInstanceFromCluster clusteredStepInstances={data} />;
	return null;
};

type GroupedByRessourcesProps = {
	streamId: string;
};
const GroupedByRessources: React.FC<GroupedByRessourcesProps> = ({
	streamId,
}) => {
	const today = new Date();
	const in30days = addDays(today, 30);
	const [dateRange, setDateRange] = useState<[string, string] | undefined>([
		today.toISOString(),
		in30days.toISOString(),
	]);
	const { data: resources, isLoading: isResourceLoading } =
		useProcessStreamResourceAllocationDefinitions(streamId);

	const { nodeReferenceId, stepTemplateId } = Route.useSearch();
	if (isResourceLoading) return <LoadingMetronome />;
	return (
		<>
			<div className="flex items-center gap-2">
				<SelectResource
					resources={resources}
					nodeReferenceId={nodeReferenceId}
				/>
				<Dialog>
					<DialogTrigger className="m-1" asChild>
						<Button className="relative" variant="outline">
							<CalendarIcon className="me-1" />
							<FormattedMessage id="PLANNED_START" />
							{!!dateRange?.length && (
								<span className="absolute -top-2 -right-2 w-4 h-4 text-center text-xs shadow rounded-full bg-primary text-white">
									{"1"}
								</span>
							)}
						</Button>
					</DialogTrigger>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>
								<FormattedMessage id="FILTER_DATE_RANGE" />
							</DialogTitle>
						</DialogHeader>
						<FilterDateRange value={dateRange} setValue={setDateRange} />
					</DialogContent>
				</Dialog>
			</div>
			<div>
				<StepInstancesByRessource
					streamId={streamId}
					from={dateRange[0]}
					to={dateRange?.[1]}
					stepTemplateId={stepTemplateId ?? resources[0].stepTemplateId}
					nodeReferenceId={nodeReferenceId ?? resources[0].nodeReferenceId}
				/>
			</div>
		</>
	);
};

const OnDemandParams = v.object({
	nodeReferenceId: v.optional(v.string()),
	stepTemplateId: v.optional(v.string()),
});

export const Route = createFileRoute("/$workspaceId/stream/$streamId/onDemand")(
	{
		validateSearch: (searchParams) => v.parse(OnDemandParams, searchParams),
		loader: async ({ context, params }) => {
			const { queryClient } = context;
			const { streamId, workspaceId } = params;

			const promises = [];

			promises.push(
				queryClient.ensureQueryData(
					singleProcessStreamsOptions(workspaceId, streamId),
				),
			);

			await Promise.all(promises);
			return;
		},
		component: () => {
			const { streamId, workspaceId } = Route.useParams();
			const { data: processStream } = useQuery(
				singleProcessStreamsOptions(workspaceId, streamId),
			);

			return <OnDemandPage processStream={processStream} />;
		},
	},
);

const OnDemandPage = ({ processStream }) => {
	const [activeTab, setActiveTab] = useState("list");
	const { organizations } = useOrganizationData();
	const intl = useIntl();
	const organization = organizations?.find(
		(orga) => orga.id === processStream?.organization.id,
	);

	const state = "active"; // to refactor
	const PREFERENCE_KEY = `metronome.processStream.instances.active.${processStream.id}`;

	const { data: preferences } = useUserPreferences(PREFERENCE_KEY);

	return (
		<ProcessStreamTemplate processStream={processStream} isLoading={false}>
			<PageTitle organizationName={organization?.name}>
				{intl.formatMessage({ id: "MENU.PROCESS_STREAM" })}
			</PageTitle>
			<Tabs value={activeTab} onValueChange={setActiveTab}>
				<TabsList className="bg-white">
					<TabsTrigger className="font-bold" value="list">
						<FormattedMessage id="LIST" />
					</TabsTrigger>
					<TabsTrigger className="font-bold" value="timeline">
						<FormattedMessage id="TIMELINE" />
					</TabsTrigger>
					<TabsTrigger className="font-bold" value="resource">
						<FormattedMessage id="RESOURCES" />
					</TabsTrigger>
				</TabsList>
				<TabsContent value="list">
					<ActiveViewProvider preferences={preferences}>
						<UserProcessConfigLoader
							context={state as ProcessContext}
							key={state}
							states={["active"]}
							times={["past", "today"]}
							processStreamId={processStream.id}
							preferenceKey={PREFERENCE_KEY}
							preferences={preferences}
						/>
					</ActiveViewProvider>
				</TabsContent>
				<TabsContent value="timeline">
					<ProcessTimelineLoader processStreamId={processStream.id} />
				</TabsContent>
				<TabsContent value="resource">
					<GroupedByRessources streamId={processStream.id} />
				</TabsContent>
			</Tabs>
		</ProcessStreamTemplate>
	);
};
