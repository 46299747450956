import type React from "react";
import { Avatar } from "@metronome/components/Avatar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { getInitials } from "@metronome/utils/formatText";
import { useName, getUserInfoUri } from "@metronome/utils/user";
import type { IParty } from "@metronome/types/Party";
import {
	CheckCircledIcon,
	CircleIcon,
	LapTimerIcon,
} from "@radix-ui/react-icons";
import { Select, SelectContent, SelectItem, SelectTrigger } from "./ui/select";
import { FormattedMessage } from "react-intl";
import type { PartyAssignmentApiModel } from "@metronome/types/PartyAssignment";
import clsx from "clsx";

function isExtendedUser(user: IParty | ExtendedIParty): user is ExtendedIParty {
	return !!(
		(user as ExtendedIParty).status &&
		(user as ExtendedIParty).origin &&
		(user as ExtendedIParty).roleName
	);
}

type ExtendedIParty = IParty & {
	status: PartyAssignmentApiModel["status"];
	origin: PartyAssignmentApiModel["origin"];
	roleName: string;
};

const AvatarRingStatus: React.FC<{
	status: PartyAssignmentApiModel["status"];
	children: React.ReactNode;
}> = ({ children, status }) => {
	if (status)
		return (
			<div
				className={clsx({
					"rounded-full border-2 border-green-600 relative":
						status === "acknowledged",
					"rounded-full border-2 border-blue-500 border-dashed relative":
						status === "waitlist",
					"rounded-full border-2 border-green-800 relative":
						status === "regulation",
					"relative border-2 border-transparent": status === "undefined",
				})}
			>
				{children}
			</div>
		);
	return <>{children}</>;
};

const AvatarStatusIcon: React.FC<{
	status: PartyAssignmentApiModel["status"];
}> = ({ status }) => {
	return (
		<div className="absolute bg-white bottom-0 -right-1 rounded-full">
			{status === "acknowledged" && (
				<CheckCircledIcon className="text-green-600" />
			)}
			{status === "waitlist" && <LapTimerIcon className="text-blue-500" />}
		</div>
	);
};

const UserPopoverComplete: React.FC<{
	user: IParty | ExtendedIParty;
	size?: number;
}> = ({ user, size = 32 }) => {
	const initials = getInitials(user?.firstName, user?.lastName);
	const status = isExtendedUser(user) ? user.status : "undefined";
	return (
		<Popover>
			<PopoverTrigger className="p-0 m-0 bg-transparent">
				<AvatarRingStatus status={status}>
					<Avatar size={size} src={getUserInfoUri(user)} alt={initials} />
					<AvatarStatusIcon status={status} />
				</AvatarRingStatus>
			</PopoverTrigger>
			<PopoverContent>
				<div className="flex items-center gap-2 text-base">
					<AvatarRingStatus status={status}>
						<Avatar
							size={48}
							src={getUserInfoUri(user)}
							alt={getInitials(user?.firstName, user?.lastName)}
						/>
						<AvatarStatusIcon status={status} />
					</AvatarRingStatus>
					<div className="truncate">
						<p className="font-bold">{useName(user)}</p>
						<a className="text-xs" href={`mailto:${user.email}`}>
							{user.email}
						</a>
					</div>
				</div>
				<div className="pt-4 pb-2 grid grid-cols-2 gap-2 items-center">
					<Select>
						<SelectTrigger>
							<CircleIcon className="inline text-green-600 me-1 align-text-bottom	" />
							<span className="text-green-600 font-semibold">Disponible</span>
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="unavailable">not available</SelectItem>
							<SelectItem value="rejected">Rejected</SelectItem>
						</SelectContent>
					</Select>
					{isExtendedUser(user) && user.roleName}
				</div>
				{isExtendedUser(user) && (
					<span className="text-slate-600">
						<FormattedMessage id="SOURCE" />:{" "}
						<FormattedMessage id={user.origin} />
					</span>
				)}
			</PopoverContent>
		</Popover>
	);
};

export const UserPopover: React.FC<{ user: IParty; size?: number }> = ({
	user,
	size,
}) => {
	if (user) return <UserPopoverComplete user={user} size={size} />;
	return <span />;
};
