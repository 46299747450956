import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type {
	Assignment,
	IGroupedPartyAssignmentsApiModel,
	StepInstanceAssignment,
} from "@metronome/types/PartyAssignment";
import type { AxiosResponse } from "axios";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import { apiDelete, apiGet, apiPost, apiPut } from "./api";
import type { IRoleRequirement } from "@metronome/types/RoleAssignment";

const AssignmentBaseParams = z.object({
	context: z.enum(["step-instances", "process-instances"]),
	contextId: z.string(),
});

type AssignmentBaseParams = z.infer<typeof AssignmentBaseParams>;

export function useRoles(
	context: AssignmentBaseParams["context"],
	contextId: string,
): UseQueryResult<IRoleRequirement[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useRoles: workspaceId is not defined");
	}
	return useQuery({
		queryKey: [workspaceId, context, contextId, "roles"],
		queryFn: () =>
			apiGet<IRoleRequirement[]>(
				`ws/${workspaceId}/${context}/${contextId}/roles`,
			),
	});
}

export function useStepInstanceAssignments(
	stepInstanceId: string,
): UseQueryResult<IGroupedPartyAssignmentsApiModel[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useRoles: workspaceId is not defined");
	}

	return useQuery({
		queryKey: [workspaceId, stepInstanceId, "assignments"],
		queryFn: () =>
			apiGet<IGroupedPartyAssignmentsApiModel[]>(
				`ws/${workspaceId}/step-instances/${stepInstanceId}/assignments`,
			),
	});
}

export function useUpdateAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<
	AxiosResponse<StepInstanceAssignment> | { data: undefined },
	Error,
	StepInstanceAssignment["assignments"]
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateAssignments: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: (assignees: StepInstanceAssignment["assignments"]) =>
			apiPut(`ws/${workspaceId}/${context}/${contextId}/assignments/`, {
				assignments: assignees,
			}),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, contextId, "assignments"],
			});
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export function useCreateAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<AxiosResponse | { data: undefined }, Error, Assignment> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useCreateAssignments: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ partyId, roleId }: Assignment) =>
			apiPost(`ws/${workspaceId}/${context}/${contextId}/assignments/`, {
				partyId,
				roleId,
			}),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, contextId, "assignments"],
			});
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export function useDeleteAssignments(
	context: AssignmentBaseParams["context"],
	contextId: string,
	onSuccess?: () => void,
): UseMutationResult<
	AxiosResponse | { data: undefined },
	Error,
	{ assignmentId: string }
> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useDeleteAssignments: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ assignmentId }) =>
			apiDelete(
				`ws/${workspaceId}/${context}/${contextId}/assignments/${assignmentId}`,
			),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [workspaceId, contextId, "assignments"],
			});
			onSuccess?.();
		},
		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}
