import { StepsTimeline } from "@metronome/features/StepsTimeline";
import type { ClusteredStepInstances } from "@metronome/types/StepInstance";
import type {
	Channel,
	ProgramWithOmittedUuid,
} from "@nessprim/planby-pro/dist/Epg/helpers";
import { ChannelBox, type ChannelItem } from "@nessprim/planby-pro";
import { endOfDay, format } from "date-fns";
import { calcTillDate, getStartDate } from "@metronome/utils/planby";
import { useMemo } from "react";
import type { FC } from "react";
import { useParams } from "@tanstack/react-router";

const CustomChannelItem = ({ channel }: ChannelItem) => {
	const { position, title, nbOfItems } = channel;
	return (
		<ChannelBox className="p-1 border-t flex-col z-50" {...position}>
			<span className="font-bold">{title}</span>
			<span>{nbOfItems} items</span>
		</ChannelBox>
	);
};

type StepInstanceFromClusterProps = {
	clusteredStepInstances: ClusteredStepInstances;
	selectedMetadataDefs?: string[];
};
export const StepInstanceFromCluster: FC<StepInstanceFromClusterProps> = ({
	clusteredStepInstances,
	selectedMetadataDefs,
}) => {
	const { workspaceId } = useParams({ strict: false });
	const startDate = getStartDate(clusteredStepInstances.minDateTime);

	const maxDate = endOfDay(new Date(clusteredStepInstances.maxDateTime));
	const endDate = format(maxDate, "yyyy-MM-dd'T'HH:mm:ss");

	const channels: Channel[] = useMemo(
		() =>
			clusteredStepInstances?.groups.map((g) => ({
				id: g.resource.id,
				uuid: g.resource.id,
				title: g.resource.name,
				nbOfItems: g.stepInstancesCount,
				logo: "",
				isNestedChild: false,
			})),
		[clusteredStepInstances],
	);

	const epg: ProgramWithOmittedUuid[] = useMemo(
		() =>
			clusteredStepInstances?.groups.flatMap((g) =>
				g.stepInstances.map((s) => ({
					channelUuid: g.resource.id,
					id: s.id,
					title: s.name,
					type: s.type,
					labels: s.businessDimension.labels,
					since: format(
						s.schedule.plannedAt ?? s.schedule.scheduleLowerBand,
						"yyyy-MM-dd'T'HH:mm:ss",
					),
					till: format(calcTillDate(s.schedule), "yyyy-MM-dd'T'HH:mm:ss"),
					schedule: s.schedule,
					workspaceId,
					processId: s.processInstanceId,
					assignments: s.assignments,
					resolution: s.resolution,
					selectedMetadataDefs: selectedMetadataDefs,
					businessDimension: s.businessDimension,
				})),
			),
		[clusteredStepInstances, workspaceId, selectedMetadataDefs],
	);

	return (
		<StepsTimeline
			plannedStart={startDate}
			plannedEnd={endDate}
			epg={epg}
			channels={channels}
			CustomChannelItem={CustomChannelItem}
			selectedMetadataDefs={selectedMetadataDefs}
		/>
	);
};
