import { useUpdateStepInstanceSchedule } from "@metronome/api/useStepInstance";
import { Button } from "@metronome/components/ui/button";
import { Label } from "@metronome/components/ui/label";
import { TimePickerInput } from "@metronome/components/ui/timePickerInput";
import { useFormatDurationToString } from "@metronome/utils/formatDuration";
import {
	ArrowRightIcon,
	ClockIcon,
	InfoCircledIcon,
	Pencil1Icon,
} from "@radix-ui/react-icons";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { useCallback, useState } from "react";
import { FormattedDateTimeRange, FormattedMessage } from "react-intl";
import type { ISchedule } from "@metronome/types/StepInstance";
import {
	Tabs,
	TabsList,
	TabsContent,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import { Calendar } from "@metronome/components/ui/calendar";
import type { Duration } from "duration-fns";

type CustomItemScheduleProps = {
	since: string | number | Date;
	till: string | number | Date;
	stepId: string;
	schedule: ISchedule;
	children?: React.ReactNode;
	processId?: string;
	childrenAsChild?: boolean;
};
export const CustomItemSchedule: React.FC<CustomItemScheduleProps> = ({
	since,
	till,
	stepId,
	schedule,
	children,
	processId,
	childrenAsChild,
}) => {
	const { scheduledByRegulator } = schedule;
	const sinceDate = new Date(since);
	const [startDate, setStartDate] = useState(sinceDate);
	const tillDate = new Date(till);
	const [endDate, setEndDate] = useState(tillDate);
	const { duration, durationAsString, error } = useFormatDurationToString(
		startDate,
		endDate,
	);
	const { mutate } = useUpdateStepInstanceSchedule(stepId, processId);
	const onSave = useCallback(
		(plannedDateTime: Date, plannedDuration: Duration) => {
			mutate({ plannedDateTime, plannedDuration });
		},
		[mutate],
	);

	const onSelectDate = useCallback(
		(date: Date) => {
			date.setHours(sinceDate.getHours(), sinceDate.getMinutes());

			mutate({
				plannedDateTime: date,
				plannedDuration: duration, // TODO: manually set the duration here
			});
		},
		[mutate, sinceDate, duration],
	);

	const reInitialize = useCallback(() => {
		mutate({
			plannedDateTime: schedule.scheduleLowerBand,
			plannedDuration: schedule.estimatedDuration ?? schedule.plannedDuration,
		});
	}, [
		mutate,
		schedule.estimatedDuration,
		schedule.plannedDuration,
		schedule.scheduleLowerBand,
	]);
	return (
		<Popover>
			<PopoverTrigger
				className="hover:underline group"
				asChild={childrenAsChild}
			>
				{children ?? (
					<>
						{!!scheduledByRegulator && (
							<InfoCircledIcon className="inline me-1" />
						)}
						<FormattedDateTimeRange
							day="numeric"
							month="short"
							weekday="short"
							hour="2-digit"
							minute="2-digit"
							from={new Date(since)}
							to={new Date(till)}
						/>
						<Pencil1Icon className="opacity-0 transition-opacity inline group-hover:!opacity-100" />
					</>
				)}
			</PopoverTrigger>
			<PopoverContent>
				<Tabs defaultValue="time">
					<TabsList>
						<TabsTrigger value="time">
							<FormattedMessage id="TIME" />
						</TabsTrigger>
						<TabsTrigger value="date">
							<FormattedMessage id="DATE" />
						</TabsTrigger>
					</TabsList>
					<TabsContent value="time">
						{!!scheduledByRegulator && (
							<div className=" px-1 mb-2 flex items-center justify-between borderrounded">
								<span className="text-orange-600">
									<FormattedMessage id="MODIFIED_BY_REGULATOR" />
								</span>
								<Button
									onClick={reInitialize}
									size="sm"
									variant="outlineDestructive"
								>
									<FormattedMessage id="REINITIALIZE" />
								</Button>
							</div>
						)}
						<div className="flex-row items-center flex gap-4">
							<div>
								<Label className="font-bold">
									<FormattedMessage id="PLANNED_HOUR_START" />
								</Label>
								<TimePickerInput date={startDate} setDate={setStartDate} />
							</div>
							<ArrowRightIcon />
							<div>
								<Label className="font-bold">
									<FormattedMessage id="PLANNED_HOUR_END" />
								</Label>
								<TimePickerInput date={endDate} setDate={setEndDate} />
							</div>
						</div>
						<div className="flex items-center pt-4">
							{error ? (
								<span className="text-xs text-red-500">
									<FormattedMessage id={error} />
								</span>
							) : (
								<span className="text-xs">
									<ClockIcon className="inline align-sub" /> {durationAsString}
								</span>
							)}
							<Button
								disabled={!!error}
								onClick={() => onSave(startDate, duration)}
								className="ms-auto"
							>
								<FormattedMessage id="SAVE" />
							</Button>
						</div>
					</TabsContent>
					<TabsContent value="date">
						<Calendar
							mode="single"
							selected={startDate}
							onSelect={onSelectDate}
							defaultMonth={startDate}
							initialFocus
						/>
					</TabsContent>
				</Tabs>
			</PopoverContent>
		</Popover>
	);
};
