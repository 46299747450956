import { FormattedMessage } from "react-intl";
import { z } from "zod";
import { ThirdApps } from "./ThirdApp";

const SystemDoer = z.object({
	type: z.literal("system"),
	name: ThirdApps,
});

const UserDoer = z.object({
	type: z.literal("user"),
	userId: z.string(),
	profilePictureUrl: z.string().optional(),
});

const UnknownDoer = z.object({
	type: z.literal("undefined"),
});

const AdminDoer = z.object({
	type: z.literal("metronomeSupport"),
});

export const IDoer = z.discriminatedUnion("type", [
	SystemDoer,
	UnknownDoer,
	AdminDoer,
	UserDoer,
]);

export type IDoer = z.infer<typeof IDoer>;

export function getDoerName(doer: IDoer): string | JSX.Element {
	switch (doer.type) {
		case "metronomeSupport":
			return <FormattedMessage id="METRONOME_SUPPORT" />;
		case "system":
			return doer.name;
		case "user":
			return doer.userId;
		default:
			return "";
	}
}

export function doerEquals(a: IDoer, b: IDoer): boolean {
	if (a.type !== b.type) {
		return false;
	}
	if (a.type === "system" && b.type === "system") {
		return a.name === b.name;
	}

	if (a.type === "user" && b.type === "user") {
		return a.userId === b.userId;
	}
	return true;
}
