import { useDeleteAssignments } from "@metronome/api/useAssignments";
import { Avatar } from "@metronome/components/Avatar";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@metronome/components/ui/dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "@metronome/components/ui/dropdown-menu";
import { getInitials } from "@metronome/utils/formatText";
import { getUserUri } from "@metronome/utils/user";
import { Cross2Icon, Pencil1Icon } from "@radix-ui/react-icons";
import { type FC, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { AssignmentModal } from "./Modals/AssignmentModal";
import type { IGroupedPartyAssignmentsApiModel } from "@metronome/types/PartyAssignment";

type UpdateAssignmentsProps = {
	contextId: string;
	context: "step-instances" | "process-instances";
	assignmentsGroup: IGroupedPartyAssignmentsApiModel;
	isDisabled?: boolean;
	label?: "ASSIGNEES" | "WATCHERS";
};

export const UpdateAssignments: FC<UpdateAssignmentsProps> = ({
	context,
	contextId,
	assignmentsGroup,
	isDisabled = false,
	label = "ASSIGNEES",
}) => {
	const [open, setOpen] = useState(false);

	const { mutate: deleteAssignment } = useDeleteAssignments(context, contextId);

	const onRemoveUser = useCallback(
		(assignmentId: string) => {
			deleteAssignment({ assignmentId });
		},
		[deleteAssignment],
	);

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger disabled={isDisabled} className="p-1">
					<Pencil1Icon />
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start">
					<DropdownMenuItem onSelect={() => setOpen(true)}>
						<FormattedMessage id="ADD_ASSIGNEE" />
					</DropdownMenuItem>
					<DropdownMenuSeparator />
					<DropdownMenuLabel>
						<FormattedMessage id={label} />
					</DropdownMenuLabel>
					{assignmentsGroup.assignments.map(({ party, id }) => (
						<DropdownMenuItem
							className="flex gap-2 items-center group"
							key={party.id}
							onSelect={() => onRemoveUser(id)}
						>
							<Avatar
								src={getUserUri(party)}
								alt={getInitials(party.firstName, party.lastName)}
							/>
							<div className="flex flex-col px-2">
								<span className="font-semibold">{`${party.firstName} ${party.lastName}`}</span>
								<span className="text-xs">{assignmentsGroup.role.name}</span>
							</div>
							<Cross2Icon className="ml-auto opacity-0 group-hover:opacity-100 text-red-500" />
						</DropdownMenuItem>
					))}
				</DropdownMenuContent>
			</DropdownMenu>
			<Dialog modal={true} open={open} onOpenChange={setOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							<FormattedMessage id={"ADD_ASSIGNEE"} />
						</DialogTitle>
					</DialogHeader>
					<AssignmentModal
						roleId={assignmentsGroup.role.id}
						contextId={contextId}
						context={context}
						onSuccess={() => setOpen(false)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};
