import { Button } from "@metronome/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { NodeReference } from "./Modals/ReferenceModal";
import { FormattedMessage } from "react-intl";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { useState } from "react";
import type { INodeReferenceSpec } from "@metronome/types/BusinessDimension";

type ConnectedNodesProps = {
	stepInstance: IStepInstance;
	referenceSpecs: INodeReferenceSpec[];
};
export const ConnectedNodes: React.FC<ConnectedNodesProps> = ({
	stepInstance,
	referenceSpecs,
}) => {
	const [open, setOpen] = useState(false);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="outline" className="truncate">
					<FormattedMessage id="ADD_RESOURCES" />
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogTitle>
					<FormattedMessage id="RESSOURCES" />
				</DialogTitle>
				<NodeReference
					stepInstanceId={stepInstance.id}
					node={stepInstance.businessDimension}
					nodeReferences={stepInstance.nodeReferences}
					referenceSpecs={referenceSpecs}
					onSuccess={() => setOpen(false)}
				/>
			</DialogContent>
		</Dialog>
	);
};
