import { ProcessStreamSummary } from "@metronome/features/ProcessStream/Summary";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import type React from "react";



type ProcessStreamTemplateProps = {
	processStream: IProcessStream;
	isLoading: boolean;
	children: React.ReactNode;
};

export const ProcessStreamTemplate: React.FC<ProcessStreamTemplateProps> = ({
	processStream,
	children,
	isLoading,
}) => {
	const isRecurringStream = processStream.type === "routine";

	return (
		<>
			<ProcessStreamSummary
				isLoading={isLoading}
				processStream={processStream}
			/>

			<div>{children}</div>
		</>
	);
};
