import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { apiGet } from "./api";
import type { IBatch } from "@metronome/types/Batches";

const batchesOptions = (
	workspaceId: string,
	streamId: string,
	from: string,
	countNext = 3,
	countPrevious = 1,
) =>
	queryOptions({
		queryKey: [
			workspaceId,
			streamId,
			"batches",
			from,
			countNext,
			countPrevious,
		],
		queryFn: () =>
			apiGet<IBatch[]>(
				`/ws/${workspaceId}/process-streams/${streamId}/batches`,
				{
					params: {
						from,
						countNext,
						countPrevious,
					},
				},
			),
	});

export function useBatches(
	streamId: string,
	from?: string,
	countNext?: number,
	countPrevious?: number,
) {
	const workspaceId = useWorkspaceId();
	const today = new Date().toISOString().slice(0, 10);

	if (!workspaceId) {
		throw new Error("useBatches: workspaceId is not defined");
	}
	return useQuery(
		batchesOptions(
			workspaceId,
			streamId,
			from ?? today,
			countNext,
			countPrevious,
		),
	);
}
