import { z } from "zod";

export const IParty = z.object({
	type: z.literal("party"),
	id: z.string().min(1),
	email: z.string().optional(),
	firstName: z.string().min(1),
	lastName: z.string().min(1),
	userId: z.string().optional(),
	profilePictureUri: z.string().optional(),
});

export type IParty = z.infer<typeof IParty>;
